import { useMessageStore } from '../message'
import type { GetBannerResponse, GetFacilitatorsResponse } from './types'
import { useAuthStore } from '~~/stores/auth'

const useActions = ({ state }) => {
  const messageStore = useMessageStore()
  const authStore = useAuthStore()
  const { API_URL } = useBaseUrl()

  const token = computed(() => authStore?.state?.user?.token)

  const fetchBanners = async () => {
    state.banners.isLoading = true
    try {
      const res: GetBannerResponse = await $fetch(`${API_URL}/banners`)
      state.banners = {
        data: res?.data,
        isLoading: false
      }
    } catch (error: any) {
      messageStore.notifyStdError(error?.status)
      throw createError({
        statusCode: error?.status,
        statusMessage: `fetchBanners: ${error?.message ?? error}`
      })
    } finally {
      state.banners.isLoading = false
    }
  }

  const fetchFacilitators = async () => {
    state.facilitators.isLoading = true
    try {
      const res: GetFacilitatorsResponse = await $fetch(
        `${API_URL}/highlights/facilitators`
      )
      state.facilitators = {
        data: res?.data,
        isLoading: false
      }
    } catch (error: any) {
      messageStore.notifyStdError(error?.status)
      throw createError({
        statusCode: error?.status,
        statusMessage: `fetchFacilitators: ${error?.message ?? error}`
      })
    } finally {
      state.facilitators.isLoading = false
    }
  }

  const fetchCourses = async ({ type, page, size }) => {
    state[type].isLoading = true
    try {
      const res: any = await $fetch(`${API_URL}/courses`, {
        method: 'get',
        headers: {
          Authorization: token?.value ?? ''
        },
        params: {
          sortBy: type.slice(0, -6),
          page,
          size
        }
      })
      state[type] = {
        data: res?.data,
        isLoading: false
      }
    } catch (error: any) {
      messageStore.notifyStdError(error?.status)
      throw createError({
        statusCode: error?.status,
        statusMessage: `fetchCourses: ${error?.message ?? error}`
      })
    } finally {
      state[type].isLoading = false
    }
  }

  const fetchFeaturedCourse = async () => {
    state.featuredCourse.isLoading = true
    try {
      const res: any = await $fetch(`${API_URL}/courses/featured`, {
        headers: {
          Authorization: token?.value ?? ''
        }
      })
      state.featuredCourse = {
        data: res?.data,
        isLoading: false
      }
    } catch (error: any) {
      messageStore.notifyStdError(error?.status)
      throw createError({
        statusCode: error?.status,
        statusMessage: `fetchFeaturedCourse: ${error?.message ?? error}`
      })
    } finally {
      state.featuredCourse.isLoading = false
    }
  }

  return {
    fetchBanners,
    fetchFacilitators,
    fetchCourses,
    fetchFeaturedCourse
  }
}

export default useActions
