<script setup lang="ts">
import { useUserStore } from '~~/stores/user'

withDefaults(
  defineProps<{
    currentStep?: number
  }>(),
  {
    currentStep: 0
  }
)
const emits = defineEmits(['start', 'submit', 'skip'])
const userStore = useUserStore()
const userDetail = computed(() => userStore.state.userDetail)

const triggerStart = () => {
  emits('start')
}
const triggerSkip = () => {
  emits('skip')
}
</script>

<template>
  <div class="onboarding-body__container">
    <div class="onboarding-content">
      <div class="onboarding-intro">
        <h2 class="onboarding-intro__title">
          {{
            currentStep === 0
              ? `Hai ${
                  userDetail?.data?.fullName ?? userDetail?.data?.email
                }, Selamat Datang di Kognisi.id!`
              : 'Selamat Belajar di Kognisi.id!'
          }}
        </h2>
        <div class="mb-6">
          <nuxt-img
            v-if="currentStep === 0"
            class="mx-auto"
            src="/images/onboarding-img.svg"
            alt="onboarding"
            loading="lazy"
            sizes="10vw"
            format="webp"
            quality="80"
            preload
          />
          <nuxt-img
            v-else
            class="mx-auto"
            src="/images/onboarding-start.svg"
            alt="onboarding-start"
            loading="lazy"
            sizes="10vw"
            format="webp"
            quality="80"
            preload
          />
        </div>
        <p class="m-0 text-base">
          Terima kasih sudah memperkenalkan diri kamu, sekarang mari kita cari
          kelas dan mulai belajar.
        </p>
      </div>
    </div>
    <div class="onboarding-action">
      <template v-if="currentStep === 0">
        <a-button block type="primary" size="large" @click="triggerStart">
          Mulai
        </a-button>
        <a-button
          class="mt-4 mobile-hide"
          block
          type="text"
          size="large"
          @click="triggerSkip"
          >Lewati</a-button
        >
      </template>
      <a-button
        v-else
        block
        type="primary"
        size="large"
        @click="emits('submit')"
        >Jelajahi Kognisi.id</a-button
      >
    </div>
  </div>
</template>

<style lang="scss">
@import './styles.scss';
</style>
