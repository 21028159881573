<script setup lang="ts">
import { useAuthStore } from '~~/stores/auth'
import { useHomeStore } from '~~/stores/home'
import { useUserStore } from '~~/stores/user'

const authStore: Record<string, any> = useAuthStore()
const userStore: Record<string, any> = useUserStore()
const homeStore: Record<string, any> = useHomeStore()

const isLoggedIn = computed(() => authStore.state?.isLoggedIn)
const facilitators = computed(() => homeStore.state?.facilitators)
const isProcessingState = computed(() => userStore.state.isProcessingState)
const featuredCourse = computed(() => homeStore.state?.featuredCourse)
const latestCourse = computed(() => homeStore.state?.latestCourse)
const favoritedCourse = computed(() => homeStore.state?.favoritedCourse)

const showOnboarding = ref(isLoggedIn.value && isProcessingState.value)

onMounted(() => {
  homeStore.fetchFacilitators()
  homeStore.fetchFeaturedCourse()
  homeStore.fetchCourses({
    type: 'latestCourse',
    size: 6,
    page: 1
  })
  homeStore.fetchCourses({
    type: 'favoritedCourse'
  })
})
</script>

<template>
  <div>
    <LazyOrganismsNavbar />
    <div class="kg-content">
      <div class="section-home">
        <div class="container">
          <LazyModulesHomeMoleculesBanner />
          <!-- <LazyModulesHomeMoleculesSponsor /> -->
          <LazyModulesHomeMoleculesSectionCourseContinue
            v-if="isLoggedIn"
            type-ref="kursusContinue"
            title="Lanjutkan Belajarmu"
            is-see-more
            class="my-10"
          />
          <!-- <LazyModulesHomeMoleculesSectionCourse
            v-if="isLoggedIn"
            type-ref="kursusSuggest"
            title="Kursus Paling Sesuai untuk Kamu"
            class="my-7"
            type="suggestion"
            @show-onboarding="showOnboarding = true"
          /> -->
          <LazyModulesHomeMoleculesSectionCourseLoading
            v-if="featuredCourse?.isLoading"
            title="Kursus Unggulan"
            class="my-10"
          />
          <LazyModulesHomeMoleculesSectionCourse
            v-else
            type-ref="kursusUnggulan"
            title="Kursus Unggulan"
            :data="featuredCourse?.data ?? []"
            :is-loading="featuredCourse?.isLoading ?? false"
            class="my-10"
          />

          <LazyModulesHomeMoleculesSectionCourseLoading
            v-if="latestCourse?.isLoading"
            title="Kursus Terbaru"
            class="my-10"
          />
          <LazyModulesHomeMoleculesSectionCourse
            v-else
            type-ref="kursusTerbaru"
            title="Kursus Terbaru"
            :data="latestCourse?.data ?? []"
            :is-loading="latestCourse?.isLoading ?? false"
            is-see-more
            with-arrow
            class="my-10"
          />

          <LazyModulesHomeMoleculesDiscovery />

          <LazyModulesHomeMoleculesSectionCourseLoading
            v-if="favoritedCourse?.isLoading"
            title="Kursus Terfavorit"
            class="my-10"
          />
          <LazyModulesHomeMoleculesSectionCourse
            v-else
            type-ref="kursusTerfavorit"
            title="Kursus Terfavorit"
            :data="favoritedCourse?.data ?? []"
            :is-loading="favoritedCourse?.isLoading ?? false"
            is-see-more
            with-arrow
            class="my-10"
          />
        </div>
        <div class="section-mentor">
          <div class="container">
            <LazyModulesHomeMoleculesSectionMentor
              :data="facilitators?.data ?? []"
              :is-loading="facilitators?.isLoading"
              title="Profil Fasilitator"
            />
          </div>
        </div>
      </div>

      <slot />
    </div>
    <LazyOrganismsFooter />
    <OrganismsOnboarding v-if="showOnboarding" @skip="showOnboarding = false" />
  </div>
</template>

<style lang="scss">
@import './styles.scss';
</style>
