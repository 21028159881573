<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    currentStep?: number
  }>(),
  {
    currentStep: 0
  }
)

const emits = defineEmits(['skip'])

const { currentStep } = toRefs(props)
const triggerSkip = () => {
  emits('skip')
}
</script>

<template>
  <div class="onboarding-head">
    <div
      class="onboarding-head__image"
      :class="currentStep > 0 ? 'mobile-hide' : null"
    >
      <img
        src="~/assets/images/logo-kognisi.svg"
        width="182"
        alt="kognisi logo"
        loading="lazy"
      />
    </div>

    <div v-if="currentStep > 0" class="onboarding-head__step">
      <a-steps
        progress-dot
        :current="currentStep"
        :initial="1"
        :responsive="false"
        class="justify-center"
      >
        <a-step />
        <a-step />
        <a-step />
      </a-steps>
    </div>

    <div
      class="onboarding-head__skip"
      :class="currentStep === 0 ? 'md:hidden' : 'hidden'"
      @click="triggerSkip"
    >
      Lewati
    </div>
  </div>
</template>

<style lang="scss">
@import './styles.scss';
</style>
