import { defineStore } from 'pinia'

import useActions from './actions'
import { type HomeStateType } from './types'

export const useHomeStore = defineStore('home', () => {
  const state: HomeStateType = reactive({
    banners: {
      data: [],
      isLoading: true
    },
    facilitators: {
      data: [],
      isLoading: true
    },
    featuredCourse: {
      data: [],
      isLoading: true
    },
    latestCourse: {
      data: [],
      isLoading: true
    },
    favoritedCourse: {
      data: [],
      isLoading: true
    }
  })

  const actions = useActions({
    state
  })
  return {
    state,
    ...actions
  }
})
