import { JsonFormType } from 'types/entity'
import { useOnboardingStore } from '~~/stores/onboarding'

export default function useForms() {
  const onboardingStore = useOnboardingStore()

  const { validateString, validateRequired } = useValidationForm()
  const dataState1 = ref({
    occupation: null,
    field: null,
    topic: []
  })
  const resetData1 = {
    occupation: null,
    field: null,
    topic: []
  }
  const rulesRef1 = {
    occupation: [
      { required: true, validator: validateString, trigger: 'change' }
    ],
    field: [{ required: true, validator: validateString, trigger: 'change' }],
    topic: [{ required: true, validator: validateRequired, trigger: 'change' }]
  }

  const jsonForm1 = computed((): JsonFormType[] => [
    {
      key: 'occupation',
      label: 'Saat ini kamu sedang berprofesi sebagai apa?',
      placeholder: 'Pilih Profesi',
      component: 'select',
      options: onboardingStore?.state?.occupations?.data ?? [],
      size: 'large'
    },
    {
      key: 'field',
      label: 'Bidang apa yang sedang kamu tekuni saat ini?',
      placeholder: 'Pilih Bidang',
      component: 'select',
      options: onboardingStore?.state?.fields?.data ?? [],
      size: 'large'
    },
    {
      key: 'topic',
      label: 'Topik apa yang menarik untuk kamu?',
      placeholder: 'Pilih topik (kamu bisa pilih lebih dari 1)',
      component: 'select-multiple-tag',
      options: onboardingStore?.state?.topics?.data ?? [],
      size: 'large'
    }
  ])

  const dataState2 = ref({
    dob: null,
    reason: null
  })
  const resetData2 = {
    dob: null,
    reason: null
  }
  const rulesRef2 = {
    reason: [{ required: true, validator: validateString, trigger: 'change' }],
    dob: [{ required: true, validator: validateRequired, trigger: 'change' }]
  }

  const jsonForm2 = computed((): JsonFormType[] => [
    {
      key: 'dob',
      label: 'Berapa tanggal lahirmu?',
      placeholder: 'Pilih Tanggal Lahir',
      component: 'datePicker',
      size: 'large',
      format: 'DD/MM/YYYY'
    },
    {
      key: 'reason',
      label: 'Kenapa kamu ingin belajar di kognisi.id?',
      placeholder: 'Pilih Alasan',
      component: 'select',
      options: onboardingStore?.state?.studyReasons?.data ?? [],
      size: 'large'
    }
  ])

  return {
    jsonForm1,
    dataState1,
    rulesRef1,
    resetData1,
    jsonForm2,
    dataState2,
    rulesRef2,
    resetData2
  }
}
