<script setup lang="ts">
import dayjs from 'dayjs'

import useForms from './useForms'
import { useOnboardingStore } from '~~/stores/onboarding'
import { useUserStore } from '~~/stores/user'
import { type DataOnboardingType } from '~~/stores/user/types'
import type {
  FieldsTypes,
  OccupationTypes,
  StudyReasonTypes,
  TopicsTypes
} from '~~/types/entity'

const onboardingStore: Record<string, any> = useOnboardingStore()

const occupations = computed(() => onboardingStore.state?.occupations)
const fields = computed(() => onboardingStore.state?.fields)
const topics = computed(() => onboardingStore.state?.topics)
const studyReasons = computed(() => onboardingStore.state?.studyReasons)

const emits = defineEmits(['skip'])

const userStore = useUserStore()
const userDetail = computed(() => userStore.state.userDetail)

const {
  jsonForm1,
  dataState1,
  rulesRef1,
  resetData1,
  jsonForm2,
  dataState2,
  rulesRef2,
  resetData2
} = useForms()
const currentStep = ref(0)
const formData = ref<DataOnboardingType>(null)

const triggerStart = () => {
  currentStep.value = 1
}

const onUpdateOnboarding = () => {
  userStore.updateUserOnBoarding({
    payload: {
      data: formData.value,
      userSerial: userDetail.value?.data?.serial
    },
    resolve: () => {
      userStore.getUserDetail({
        resolve: () => {
          if (userStore.processingStates.includes(formData.value?.state)) {
            currentStep.value += 1
          } else {
            window.location.reload()
          }
        }
      })
    }
  })
}

const onFinishProfession = () => {
  const choosenTopic: string[] = []
  const data: DataOnboardingType = {
    occupationSerial:
      occupations.value?.data?.filter(
        (item: OccupationTypes) => item.name === dataState1?.value?.occupation
      )[0].serial ?? null,
    fieldSerial:
      fields.value?.data?.filter(
        (item: FieldsTypes) => item.name === dataState1?.value?.field
      )[0].serial ?? null,
    interestTopicSerials: '',
    state: 'PROFESSION'
  }

  topics.value?.data?.forEach((item: TopicsTypes) => {
    if (dataState1?.value?.topic?.includes(item?.name)) {
      choosenTopic.push(item?.serial)
    }
  })
  data.interestTopicSerials = choosenTopic?.join(',')

  formData.value = { ...formData.value, ...data }

  onUpdateOnboarding()
}

const onFinishDob = () => {
  formData.value = {
    ...formData.value,
    studyReasonSerial:
      studyReasons.value?.data?.filter(
        (item: StudyReasonTypes) => item.name === dataState2?.value?.reason
      )[0].serial ?? null,
    dob: new Date(dataState2?.value?.dob?.split('/').reverse().join('-')),
    state: 'DOB_AND_REASON'
  }

  onUpdateOnboarding()
}

const onBack = () => {
  currentStep.value -= 1
}

const onSubmit = () => {
  formData.value = {
    ...formData.value,
    state: 'FINISHED'
  }

  onUpdateOnboarding()
}

const fetchDefaultAPI = () => {
  onboardingStore.fetchOccupations()
  onboardingStore.fetchStudyReason()
  onboardingStore.fetchTopics()
  onboardingStore.fetchFields()
}

fetchDefaultAPI()
</script>

<template>
  <div class="onboarding">
    <AtomsOnboardingWrapper :current-step="currentStep" @skip="emits('skip')">
      <div class="onboarding-body">
        <AtomsOnboardingIntro
          v-if="currentStep === 0"
          @start="triggerStart"
          @skip="emits('skip')"
        />
        <div v-else-if="currentStep === 1" class="onboarding-body__container">
          <AtomsOnboardingQuestion
            :form-data="{
              id: 'form-step-1',
              jsonForm: jsonForm1,
              dataState: dataState1,
              rulesRef: rulesRef1,
              resetData: resetData1
            }"
            @on-finish="onFinishProfession"
          />
        </div>
        <div v-else-if="currentStep === 2" class="onboarding-body__container">
          <AtomsOnboardingQuestion
            :form-data="{
              id: 'form-step-2',
              jsonForm: jsonForm2,
              dataState: dataState2,
              rulesRef: rulesRef2,
              resetData: resetData2
            }"
            show-back
            @on-back="onBack"
            @on-finish="onFinishDob"
          />
        </div>
        <AtomsOnboardingIntro
          v-else
          :current-step="currentStep"
          @submit="onSubmit"
        />
      </div>
    </AtomsOnboardingWrapper>
  </div>
</template>

<style lang="scss">
@import './styles.scss';
</style>
